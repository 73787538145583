.mobile-show {
	display: none;
}

@media (min-width: 992px) {
	// .navbar-expand-lg .navbar-nav .nav-link {
	// 	padding-right: 1.2rem;
	// 	padding-left: 1.2rem;
	// }

}

@media (max-width: 768px) {
	.mobile-hide {
		display: none;
	}

	button:focus {
		outline: none;
	}

	.mobile-show {
		display: initial;
	}

	.btn {
		padding: 0.23175rem 0.75rem;
	}


	.side-menu {
		display: none;
		position: relative !important;
		height: auto;
	}

	.breadcrumb {
		margin-bottom: 0;
	}

	.page-header {
		margin-top: 0;
		margin-right: 0;
		margin-bottom: 0;
		padding-bottom: 0;

		.navbar-nav {
			margin-top: 0.618rem;

			.nav-item a {
				padding: 3px 0;
			}
		}
	}

	.page-body {
		margin-bottom: 3rem;
		margin-right: 0;

		&>.col-md-10 {
			padding-right: 0;
		}

		.page-content {
			padding: 0;


			.data-table {
				margin-bottom: 0;
			}
		}
	}

	.section-pagination {
		display: inherit;
		padding: 0 8px;
		margin-bottom: 0.618rem;

		&>font {
			display: block;

		}

		.pagination-nav {
			margin-left: 0;
		}
	}

	.table-area {
		.table-filter form {
			padding-top: 1rem;
			display: flex;
			justify-content: space-between;
		}
	}

	.data-table {
		display: block;
		width: 100%;

		.single-item {
			&:not(.expanded) {
				td {
					display: none;
				}
			}

			td:first-child {
				display: inherit;
			}
		}

		tbody,
		tr,
		td {
			display: block;
			width: 100%;
		}

		thead {
			display: none;
		}


		tr {
			border-bottom: 4px double white;
		}

		td {
			text-align: right;
			padding-left: 50%;
			text-align: right;
			position: relative;
		}

		td::before {
			content: attr(data-label);
			position: absolute;
			left: 0;
			width: 50%;
			padding-left: 0.234rem;
			font-size: 15px;
			text-align: left;
		}

		.date-view {
			width: initial;
		}
	}
}